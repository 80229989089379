export const data = {
    fr: {
        tabs: ['ACTU', 'MEDIA', 'ACCUEIL'],
        playgroundText: [
            "Après plus de deux ans de travail, Etienne Machine présente son nouvel album, « A Playground for the Crowd », à paraître le 24 novembre 2023 chez Hummus Records.",
            "Entre électricité des guitares et électronique des synthétiseurs, ce sont dix titres profonds, efficaces et sincères qui racontent les dérives engendrées par l’omniprésence des réseaux sociaux et la transformation des relations interpersonnelles propre à notre époque, avec comme fil rouge un constat acerbe sur nos addictions aux machines qui composent le quotidien du 21ème siècle.",
            "Les multiples chanteur.euses que comportent le projet s’alternent et se répondent pour mieux se lier au long d’envolées communes et jouissives, le tout ligaturé par la finesse et l’indéniable sensibilité des compositions du groupe. Du spleen à la révolte, Etienne Machine accompagne l’auditeur.trice au travers de ses multiples facettes, empruntant des éléments propre à la noirceur post-punk, aux envolées synth-wave et à la ferraille maousse propre au rock alternatif britannique. Un mélange clair-obscur où l’on retrouve certaines teintes connues : Arcade Fire, Black Country New Road, Fontaines D.C, MGMT, Moderat et d’autres forment le paysage où Etienne Machine prend sa source.",
            "Considéré par ses acteur.trices et ses partenaires comme la confirmation du potentiel artistique du groupe, « A Playground for the Crowd » témoigne de l’expérience et de l’expertise de ses créateurs.trices, tous et toutes impliqué.es dans une multitude de projets significatifs de la scène musicale suisse depuis une dizaine d’année."
        ],
        preOrder: 'Commander (album & merch)',
        streamNow: 'Streamer',
        bandBio: [
            "L’humain ou la machine, une dualité évoquant un spleen bien connu du 21e siècle, où sentiments et sensations se frottent au froid des ordinateurs et à la chaleur des turbines.",
            "Electronica, art-rock, shoegaze, trip-hop: Etienne Machine aime ne pas devoir choisir et tend à définir son mélange stylistique sortie après sortie, tissant les liens entre les différentes influences de ses membres.",
            "Formée en 2018 et issue d'une rencontre sur les bancs de la Haute Ecole de Musique de Lausanne, la formation s’est étendue au travers de nombreuses collaborations et alliances, comme en témoigne la présence des différentes voix mixtes au travers de leur discographie.",
            "Touche-à-tout, le groupe est également co-fondateur du collectif artistique lausannois « La Machinerie », vivier d’hybridation rock et électronique qui met le doigt sur une scène bien active et dont les nombreuses sorties ne cessent de développer le solide réseau que ses acteurs et actrices ont acquis au fil des années.",
            "Après la sortie d’un premier EP « Off » en 2020, sur lequel figure le morceau « Selfspace » qui se retrouvera sur le podium du M4Music, Etienne Machine publie « Off & Off » sur le label Blizzard Audio Club en 2021. Acclamé par la presse locale et bénéficiant d’une belle visibilité à l’échelle nationale, ce disque permet aussi au groupe de suivre la relancée culturelle post-COVID de l’été 2021.",
            "Une dizaine de concerts plus tard, le groupe sort « Over & Out » , chant du cygne d’une période intense de travail marquée par la pandémie et première sortie officielle sur le label Hummus Records. Commence alors une tournée d’une quinzaine de dates au long de l’été en Suisse romande et Suisse allemande.",
            "Car si la formation se montre aventureuse en studio, Etienne Machine sait également se défendre sur les planches. Pas loin de 50 concerts sur des scènes de Suisse romande, dont Festi’neuch, l’Alhambra, le Venoge Festival, l’Amalgame, La Case à Chocs, Label Suisse ou Bikini Test, et quelques incursions de l’autre côté de la frontière suisse allemande.",
            "Dynamique, le groupe a pour désir de s’adapter au contexte de ses représentations, prenant le risque de modifier sa propre formule au gré des scènes.",
            "Une agilité permise par les centaines de concerts cumulés par ses différent.e.s acteurs et actrices. Les membres d’Etienne Machine ont toutes et tous participé à bon nombres de projets musicaux bien connus de la scène suisse, tels qu’Aliose, Billie Bird, Nnavy, Pat Burgener ou Caroline Alves.",
            "Après cinq sorties au compteur, Etienne Machine ouvre un nouveau chapitre, accompagné par Hummus Records mais également par Blizzard Audio Club au management. « A Playground for the Crowd » fait office de confirmation du potentiel artistique développé par le groupe depuis quelques années. 10 titres profonds, efficaces et sincères racontent les dérives engendrées par l’omniprésence des réseaux sociaux et la transformation des relations à notre époque actuelle."
        ],
        lineUp: {
            thibault: 'Batterie, Percussions, Synthétiseurs, Programmation, Backing Vocals',
            arno: 'Guitares, Synthétiseurs, Programmation, Vocals, Backing Vocals',
            sacha: 'Basse, Synthétiseurs, Piano',
            lea: 'Vocals, Backing Vocals',
            julien: 'Claviers, Synthétiseurs, Programmation',
            asher: 'Vocals, Backing Vocals, Synthétiseurs'
        },
        follow: 'Abonne Toi'
    },
    en: {
        tabs: ['NEWS', 'MEDIA', 'HOME'],
        playgroundText: [
            "After more than two years of hard work, Etienne Machine unveils its new album, « A Playground for the Crowd », with Hummus Records on November 24th.",
            "Between the guitar’s electricity and the electronic of synthesisers, these ten deep, powerful and sincere tracks tell of the excesses caused by the omnipresence of social medias and the transformation of interpersonal relationships in our time, with a sharp observation on our addiction to the machines that make up the daily life of the 21st century.",
            "The multiple singers alternate and respond to each other, linking up along common and enjoyable heights, tied together by the finesse and the undeniable sensitivity leaking through the band’s compositions. With spleen and revolt, Etienne Machine guides the listener through its multiple facets, borrowing elements of post-punk, synth-wave, and British alternative rock. A light and dark mix where we can find some well-known shades: Arcade Fire, Black Country New Road, Fontaines D.C. , MGMT, Moderat and others form the landscape where Etienne Machine takes its source.",
            "Considered by its members and partners as a confirmation of the band’s artistic potential, « A Playground for the Crowd » bears witness to the experience and expertise of its creators, all of whom have been involved in a multitude of significant projects on the Swiss music scene for the past ten years."
        ],
        preOrder: 'Order now (album & merch)',
        streamNow: 'Stream now',
        bandBio: [
            "Human or machine, a duality referring to a well-known spleen of the 21st century, where feelings and sensations collide with the bitter cold of computers and the heat of the engines.",
            "Electronica, art-rock, shoegaze, trip-hop: Etienne Machine tend to define its own stylistic blend throughout their multiples releases, creating a connection between the many influences of its members.",
            "Etienne Machine’s founders met at the Haute Ecole de Musique de Lausanne (CH) in 2018. Since then, the band has grown through multiple collaborations, hence the different vocalists all over their discography.",
            "The band is also a co-founder of « La Machinerie », an artistic collective focused on blending rock and electronic music, placing a particular emphasis on this much alive scene and developing a well tied network of artists and collaborators.",
            "After a debut-EP, « Off », out in 2020 and including the track « Selfspace » that would make it to the final stage of M4Music’s Demotape, the band releases « Off & Off » on Blizzard Audio Club in 2021. Acclaimed by local press and benefiting from a nice exposure, this record also allows the band to follow the post-COVID cultural revival of the summer of 2021.",
            "A dozen concerts later, the ban releases « Over & Out », marking the first collaboration between the band and Hummus Records. The band goes on tour throughout Switzerland for 15 shows during 2022’s festival season.",
            "Etienne Machine may show themselves as adventurous in the confined spaces of a recording studio, they also know how to take on a stage. Having performed around 50 concerts at venues and festivals such as Festi’neuch, L’Alhambra, L’Amalgame, Label Suisse, Venoge Festival, Bikini Test, La Case à Chocs, and a few ones in the german-speaking part of Switzerland. The group demonstrates adaptability to different performance contexts, taking the risk of modifying their formula according to the scene.",
            "This agility is made possible by the hundreds of concerts that its various actors have performed. The members of Etienne Machine have all participated in a number of well-known musical projects on the Swiss scene, such as Aliose, Billie Bird, Nnavy, Pat Burgener or Caroline Alves.",
            "After five releases, Etienne Machine opens a new chapter, accompanied by Hummus Records but also by Blizzard Audio Club for the management. « A Playground for the Crowd » confirms the artistic potential developed by the band over the last few years. 10 deep, efficient and sincere tracks tell the story of the drifts caused by the omnipresence of social networks and the transformation of relationships in our current era."
        ],
        lineUp: {
            thibault: 'Drums, Percussions, Synthesizers, Programing, Backing Vocals',
            arno: 'Guitars, Synthesizers, Programing, Vocals, Backing Vocals',
            sacha: 'Bass, Synthesizers, Piano',
            lea: 'Vocals, Backing Vocals',
            julien: 'Keyboards, Synthesizers, Programing',
            asher: 'Vocals, Backing Vocals, Synthesizers'
        },
        follow: 'Follow Us'
    },
    concerts: [
        {
            venue: 'Chat Noir / release concert',
            city: 'Carouge (GE)',
            date: '24/11/23',
            dateForComparison: '2023-11-24',
            info: 'https://www.chatnoir.ch/artistes/etienne-machine/',
            tickets: 'https://infomaniak.events/shop/YawJG6BQ2f/'
        },
        {
            venue: 'Le Bourg / release concert',
            city: 'Lausanne (VD)',
            date: '25/11/23',
            dateForComparison: '2023-11-25',
            info: null,
            tickets: 'https://infomaniak.events/shop/t3bkrpUNv6/'
        },
        {
            venue: 'La Parenthèse',
            city: 'Nyon (VD)',
            date: '15/12/23',
            dateForComparison: '2023-12-15',
            info: 'http://www.bar-laparenthese.ch/concerts/etienne-machine-humus-records-ch-a-playground-for-the-crowd/',
            tickets: null
        },
        {
            venue: 'NED Music Club',
            city: 'Montreux (VD)',
            date: '27/01/24',
            dateForComparison: '2024-01-27',
            info: 'https://www.ned.ch/evenements/etienne-machine-yet-no-yokai',
            tickets: null
        },
        {
            venue: 'Radio Vostok',
            city: 'Genève (GE)',
            date: '01/02/24',
            dateForComparison: '2024-02-01',
            info: 'https://radiovostok.ch/category/programme/vostok-sessions/',
            tickets: null
        },
        {
            venue: 'La Case A Chocs (QKC)',
            city: 'Neuchâtel (NE)',
            date: '16/02/24',
            dateForComparison: '2024-02-16',
            info: 'https://case-a-chocs.ch/event/etienne+machine+terne+2024-02-16',
            tickets: 'https://www.petzi.ch/en/events/54662-case-a-chocs-etienne-machine-ch-terne-fr/'
        },
        {
            venue: 'Antigel (PTR) - opening for beach fossils',
            city: 'Genève (GE)',
            date: '23/02/24',
            dateForComparison: '2024-02-23',
            info: 'https://antigel.ch/event/beach-fossils/',
            tickets: 'https://infomaniak.events/shop/MkZnP0i1Gn/event/1095948/'
        },
        {
            venue: 'Le Port Franc',
            city: 'Sion (VS)',
            date: '26/04/24',
            dateForComparison: '2024-04-26',
            info: 'https://www.leportfranc.ch/concerts/668-etienne-machine',
            tickets: 'https://infomaniak.events/shop/Mw4EiVx45q/event/1120296/'
        },
        {
            venue: 'Undertown',
            city: 'Meyrin (GE)',
            date: '08/06/24',
            dateForComparison: '2024-06-08',
            info: 'https://www.undertown.ch/show/the-hoods-off/',
            tickets: 'https://www.petzi.ch/en/events/56197-undertown-the-hoods-off-etienne-machine/'
        },
        {
            venue: 'Bleu Lézard',
            city: 'Lausanne (VD)',
            date: '20/06/24',
            dateForComparison: '2024-06-20',
            info: 'https://bleu-lezard.ch/programmation/etienne-machine-ch/',
            tickets: 'https://www.petzi.ch/fr/events/55789-cave-du-bleu-lezard-etienne-machine-ch/'
        },
        {
            venue: 'Montreux Jazz Festival',
            city: 'Montreux (VD)',
            date: '11/07/24',
            dateForComparison: '2024-07-11',
            info: 'https://www.montreuxjazzfestival.com/en/artist/etienne-machine/',
            tickets: null
        },
        {
            venue: 'Festi\'Cheyres',
            city: 'Cheyres (FR)',
            date: '12/07/24',
            dateForComparison: '2024-07-12',
            info: 'https://www.festicheyres.ch/groupe/etienne-machine/',
            tickets: null
        },
        {
            venue: 'Viva Pesta',
            city: 'Yverdon (VD)',
            date: '16/08/24',
            dateForComparison: '2024-08-16',
            info: 'https://www.yverdon-les-bains.ch/vivapesta/16-aout.html',
            tickets: null
        },
        {
            venue: 'Humus Fest (Usine à Gaz)',
            city: 'Nyon (VD)',
            date: '07/12/24',
            dateForComparison: '2024-12-07',
            info: 'https://usineagaz.ch/event/humus-fest/',
            tickets: 'https://discover.smeetz.com/fr/product/67363/booking/humus-fest-2024'
        }
    ],
    videos: [
        {
            link: 'https://www.youtube.com/embed/ACfYPeOw050?si=rOCFgGI46NRhypSZ',
            title: 'No Way Around - Live at the Playground',
        },
        {
            link: 'https://www.youtube.com/embed/L4qeTbIWuO8?si=oS1V1WVG_erOQX78',
            title: 'Transmissions - Live at the Playground',
        },
        {
            link: 'https://www.youtube.com/embed/AiEiH8VaGl0?si=ewbXmnUOUeh3BFvX',
            title: 'What If I Say? - Live at the Playground',
        },
        {
            link: 'https://www.youtube.com/embed/csSsxNzv7n4?si=-JtlT2lypkwO-5Ly',
            title: 'The Recipe - Live at the Playground',
        },
        {
            link: 'https://www.youtube.com/embed/ikv-L81w4HE?si=cWM4MjnSKJQtTbc6',
            title: 'Etienne Machine - What if I Say? (Official Video)',
        },
        {
            link: 'https://www.youtube.com/embed/2O_CzNFHp7s?si=Sgpvey2SM193u6hr',
            title: 'Etienne Machine - Ferocious People (Official Video)',
        },
        {
            link: 'https://www.youtube.com/embed/BfkSjIA-zo0',
            title: 'Etienne Machine - Alarms',
        },
        {
            link: 'https://www.youtube.com/embed/GTKDMtS31Kc',
            title: 'Etienne Machine - Rift (Erased Your Face)',
        },
        {
            link: 'https://www.youtube.com/embed/icBMyY2cc98',
            title: 'Etienne Machine - Off &amp; Off: Live Session',
        }
    ]
}
